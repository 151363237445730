<template>
  <div id="scroll-container"><Hero /><!-- Header + Hero --></div>
  <div class="relative">
    <div class="sticks z-30 sticky-bar">
      <div class="container mx-auto px-5 flex items-center relative">
        <!-- Logo -->
        <a
          href="/"
          title="CDU - Die Enteignungspartei"
          id="whdg-logo"
          class="absolute"
        >
          <img
            src="/wer-hat-der-gibt-logo.svg"
            alt="Logo | Wer hat, der gibt"
            width="120"
            height="53"
            class="bg-white"
          />
        </a>
      </div>
    </div>
    <div
      class="
        absolute
        left-0
        right-0
        top-0
        py-5
        h-20
        px-5
        max-w-full
        overflow-x-hidden
        flex
        items-center
        justify-end
        lg:justify-center
        service-line
      "
    >
      <div
        class="
          container
          text-gray-500 text-center
          md:text-right
          lg:text-center
          leading-tight
          whitespace-normal
          sizing
        "
      >
        Ein Service des Umverteilungsbündnisses »Wer hat, der gibt«
      </div>
    </div>

    <main>
      <Facts :states="states" :annualTotals="annualTotals" />
      <Samples />
      <Context />
      <Summary />
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Context from "./components/Context.vue";
import Facts from "./components/Facts.vue";
import Footer from "./components/Footer.vue";
import Hero from "./components/Hero.vue";
import Samples from "./components/Samples.vue";
import Summary from "./components/Summary.vue";

import * as data from "./assets/data/data.json";
import { IDb } from "./interfaces/db.interface";
import { IState } from "./interfaces/state.interface";
import { IAnnualTotalExpropriations } from "./interfaces/annual-total-expropriations.interface";

@Options({
  components: {
    Context,
    Hero,
    Facts,
    Samples,
    Summary,
    Footer,
  },
})
export default class App extends Vue {
  states!: IState[];
  annualTotals!: IAnnualTotalExpropriations[];

  created(): void {
    const { states, annualTotals } = data as IDb;
    this.states = states ?? [];
    this.annualTotals = annualTotals ?? [];
  }
}
</script>

<style>
/* Sticky Bar */
@media screen and (max-width: 319px) {
  .service-line {
    @apply hidden;
  }
}
#whdg-logo {
  @apply relative;
  @apply pt-3;
  min-width: 120px;
}
.sizing {
  @apply right-0;
  @apply text-xs;
  @apply max-w-min;
}
@screen sm {
  .sizing {
    @apply text-sm;
    @apply max-w-max;
  }
}
@screen md {
  .sizing {
    @apply text-base;
  }
}
@screen lg {
  .sizing {
    @apply text-base;
    @apply inset-x-0;
    @apply max-w-none;
    @apply justify-center;
  }
}

/* Toggle sticky logo */
.sticks {
  @apply sticky;
  @apply top-6;
  @apply h-8;
}
.sticks.sticky-bar {
  @apply top-0;
  min-height: 80px;
}
.sticky_sentinel {
  @apply absolute;
  @apply left-0;
  @apply right-0;
  @apply invisible;
}
.sticky_sentinel--top {
  @apply h-5;
  @apply top-0;
}
.sticky_sentinel--bottom {
  @apply h-12;
  @apply bottom-0;
}
</style>
