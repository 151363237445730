import { createApp } from "vue";
import App from "./App.vue";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@toast-ui/chart/dist/toastui-chart.min.css";

import "./styles/tailwind.css";
import "./styles/typography.css";
import "./styles/basics.css";

createApp(App).mount("#app");
