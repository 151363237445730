
import { Options, Vue } from "vue-class-component";

import Context from "./components/Context.vue";
import Facts from "./components/Facts.vue";
import Footer from "./components/Footer.vue";
import Hero from "./components/Hero.vue";
import Samples from "./components/Samples.vue";
import Summary from "./components/Summary.vue";

import * as data from "./assets/data/data.json";
import { IDb } from "./interfaces/db.interface";
import { IState } from "./interfaces/state.interface";
import { IAnnualTotalExpropriations } from "./interfaces/annual-total-expropriations.interface";

@Options({
  components: {
    Context,
    Hero,
    Facts,
    Samples,
    Summary,
    Footer,
  },
})
export default class App extends Vue {
  states!: IState[];
  annualTotals!: IAnnualTotalExpropriations[];

  created(): void {
    const { states, annualTotals } = data as IDb;
    this.states = states ?? [];
    this.annualTotals = annualTotals ?? [];
  }
}
