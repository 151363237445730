
import { Vue } from "vue-class-component";

export default class Footer extends Vue {
  get year(): number {
    const d = new Date();
    const y = d.getFullYear();
    return y;
  }
}
