<template>
  <section id="beispiele" class="w-screen bg-gray-light py-16">
    <div class="container mx-auto px-5">
      <div class="max-read-width">
        <p>
          Während Enteignungen von Privatgrundstücken etwa beim Bau von
          Ortsverbindungsstraßen gerechtfertigt sein mögen, muss jedoch für sich
          genommen der Bau neuer Autobahnen und Bundesstraßen in Anbetracht des
          Klimawandels und der benötigten Verkehrswende radikal in Zweifel
          gezogen werden.
        </p>
        <p class="mt-12">
          <strong
            >Für die Union reicht das Wohl der Allgemeinheit aber offenbar nur
            bis zu den Leitplanken.</strong
          >
        </p>
        <p class="mt-12">
          Denn was sie für Autofahrer*innen aktiv durchsetzt, will die Union ums
          Verrecken nicht für Mieter*innen gelten lassen. Deshalb bedient sie
          sich eines
          <a
            href="https://pbs.twimg.com/media/EzWwhbwUYAwhPbb?format=jpg"
            target="_blank"
            title="Flugblatt der Berliner CDU-Fraktion"
            class="text-primary underline hover:no-underline"
          >
            scharfen ideologischen Tons</a
          >,
          <a
            href="https://mitmachen.cdu.berlin/enteignung-stoppen/"
            target="_blank"
            title="Anti-Volksbegehren Seite der Berliner CDU (Landespartei)"
            class="text-primary underline hover:no-underline"
          >
            irreführender</a
          >,
          <a
            href="https://info.cdu-fraktion-berlin.de/de/eigentum-schuetzen"
            target="_blank"
            title="Anti-Volksbegehren Seite der Berliner CDU-Fraktion"
            class="text-primary underline hover:no-underline"
          >
            plumper</a
          >, und teils
          <a
            href="https://www.nd-aktuell.de/artikel/1152515.mietwahnsinn-in-berlin-cdu-auf-allen-fronten-gegen-enteignung.html"
            target="_blank"
            title="»CDU auf allen Fronten gegen Enteignung« | Neues Deutschland vom 27.05.2021"
            class="text-primary underline hover:no-underline"
          >
            falscher Tatsachenbehauptungen</a
          >
          bei ihrem Kreuzzug gegen das Berliner Volksbegehren
          <a
            href="https://www.dwenteignen.de/"
            target="_blank"
            title="Berliner Volksbegehren »Deutsche Wohnen & Co. Enteignen«"
            class="text-primary underline hover:no-underline"
          >
            „Deutsche Wohnen &amp; Co enteignen“.</a
          >
        </p>
        <p class="mt-12">
          Für Autos wie die Weltmeister enteignen, aber Enteignungen für soziale
          Zwecke als kommunistisches Teufelszeug zu diffamieren, entblößt die
          verlogene Haltung der CDU zu ihrem eigenen konkreten Handeln.
        </p>
        <h3
          class="
            max-w-max
            xl:max-w-xl
            font-black
            text-3xl
            leading-9
            tracking-wide
            uppercase
            mt-12
          "
        >
          3 Beispiele
        </h3>
        <hr
          class="
            mt-12
            relative
            border-t border-gray-dark
            w-full
            sm:w-4/5
            mx-auto
            divider
          "
        />
        <p class="mt-12">
          Erst im Oktober 2020 beschloss die hessische CDU zusammen mit den
          Grünen eine Neufassung des Hessischen Enteignungsgesetzes. Enteignet
          werden könne demnach, um
          <a
            href="http://starweb.hessen.de/cache/DRS/20/9/03459.pdf"
            target="_blank"
            title="Gesetzentwurf der Hessischen Landesregierung zur Änderung des Enteignungsgesetzes vom 25.08.2020"
            class="text-primary underline hover:no-underline"
          >
            „Vorhaben zu verwirklichen, die dem Wohle der Allgemeinheit dienen,
            insbesondere Einrichtungen für den Sport, das Gesundheitswesen und
            andere soziale Zwecke“</a
          >. Schon vor der Gesetzesänderung wurde in Hessen
          <a
            href="https://www.fr.de/hessen/hessen-enteignungen-grund-boden-sind-alltag-12820169.html"
            target="_blank"
            title="Enteignungen sind in Hessen Alltag | Frankfurter Rundschau vom 16.07.2019"
            class="text-primary underline hover:no-underline"
          >
            fast monatlich enteignet.
          </a>
        </p>
        <hr
          class="
            mt-12
            relative
            border-t border-gray-dark
            w-full
            sm:w-4/5
            mx-auto
            divider
          "
        />
        <p class="mt-12 mb-5">
          Im Rheinischen Braunkohlerevier werden trotz Kohleausstieg u.a. für
          den Tagebau Garzweiler II ganze Dörfer zwangsumgesiedelt und ihre
          Bewohner*innen zuvor enteignet.
          <a
            href="https://www.alle-doerfer-bleiben.de/doerfer/"
            target="_blank"
            title="Webseite der Initiative »Alle Dörfer bleiben«"
            class="text-primary underline hover:no-underline"
            >Viele betroffene Dörfer</a
          >
          wehren sich gegen die Verwüstung ihrer Wohnorte für private
          Konzernprofite und eine dreckige Technologie ohne Zukunft. Und obwohl
          es bspw.
          <a
            href="https://www.youtube.com/watch?v=aJ6EaA_8ftk"
            target="_blank"
            title="Youtube-Beitrag »Pödelwitz bleibt! Wie wir das Dorf vor der Braunkohle gerettet haben"
            class="text-primary underline hover:no-underline"
            >für das Dorf Pödelwitz einen Erfolg</a
          >
          zu feiern gibt, kämpfen andere noch immer gegen RWE, die Kohlelobby
          und ihr politisches Rückgrat, die CDU.
        </p>
        <hr
          class="
            mt-12
            relative
            border-t border-gray-dark
            w-full
            sm:w-4/5
            mx-auto
            divider
          "
        />
        <p class="mt-12">
          2018
          <a
            href="https://www.welt.de/politik/deutschland/article192667349/Wohnungsnot-Wenn-CDU-und-FDP-ploetzlich-pro-Enteignung-sind.html"
            target="_blank"
            title="»Wenn CDU und FDP plötzlich für Enteignung sind«, Welt vom 29.04.2019"
            class="text-primary underline hover:no-underline"
            >stimmte die CDU in Köln für einen Antrag der Partei Die Linke,</a
          >
          demgemäß der Eigentümer eines fast ungenutzten Innenstadtgrundstücks
          zum Zwecke des Wohnungsbaus zu enteignen sei. Der politische Druck,
          die Baulücke endlich zu schließen, war so hoch, dass selbst CDU und
          FDP – zumindest vorübergehend – zur Einsicht in die Notwendigkeit zu
          bewegen waren.
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Samples extends Vue {}
</script>

<style scoped>
#beispiele {
  counter-reset: samples-counter;
}
p.first-letter::first-letter {
  @apply text-7xl;
  @apply float-left;
  @apply mr-1;
  @apply text-primary;
}
@supports (initial-letter: 1) or (-webkit-initial-letter: 1) {
  p.first-letter::first-letter {
    initial-letter: 3;
  }
}
.divider {
  counter-increment: samples-counter;
}
.divider::before {
  content: counter(samples-counter);
  @apply bg-white;
  @apply rounded-full;
  @apply border;
  @apply border-gray-dark;
  @apply font-extrabold;
  @apply font-mono;
  @apply absolute;
  @apply -top-7;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply h-14;
  @apply w-14;
  left: calc(50% - 1.4rem);
}
</style>
