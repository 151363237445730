
import { Options, Vue } from "vue-class-component";
import { defineAsyncComponent } from "vue";
// Custom stuff
import { IState } from "src/interfaces/state.interface";
import { IAnnualTotalExpropriations } from "src/interfaces/annual-total-expropriations.interface";
import { IStateExpropriationsByYear } from "src/interfaces/state-expropriations-by-year.interface";
import { IExpropriation } from "src/interfaces/expropriation.interface";

const FactsChart = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Chart.vue") as any
);
const FactsMap = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Map.vue") as any
);
const FactsParties = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Parties.vue") as any
);

@Options({
  props: {
    states: Array,
    annualTotals: Array,
  },
  components: {
    FactsChart,
    FactsMap,
    FactsParties,
  },
})
export default class Facts extends Vue {
  // Statistical data
  states!: IState[];
  annualTotals!: IAnnualTotalExpropriations[];
  totalsByState: { [key: string]: number } = {};

  public get annualTotalsNumber(): number {
    return this.annualTotals
      ?.map((annualTotal: IAnnualTotalExpropriations) => annualTotal.total)
      .reduce((totalsSum: number, currentTotal) => {
        return (totalsSum += currentTotal);
      }, 0);
  }

  public get annualTotalsAverage(): number {
    const amount = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => annualTotal.total
    ).length;
    const sum = this.annualTotals?.reduce(
      (totalsSum: number, currentTotal: IAnnualTotalExpropriations) => {
        return (totalsSum += currentTotal.total);
      },
      0
    );
    return Math.floor(sum / amount);
  }

  mounted(): void {
    if (this.states) {
      this.states.forEach((state: IState) => {
        const stateCode = state.code;
        let expropriationByState: { [key: string]: number } = {};
        const expropriations = state.expropriationsByYear
          .filter((expropriationsByYear: IStateExpropriationsByYear) =>
            expropriationsByYear ? expropriationsByYear.year === 2020 : false
          )
          .flatMap(
            (expropriationsByYear: IStateExpropriationsByYear) =>
              expropriationsByYear.expropriations
          )
          .reduce((total: number, currentAction: IExpropriation) => {
            if (
              currentAction &&
              currentAction.amount &&
              currentAction.amount > 0
            ) {
              if (currentAction.amount === 0.5) {
                return (total += 1);
              } else {
                return (total += currentAction.amount);
              }
            } else {
              return total;
            }
          }, 0);
        expropriationByState[`${stateCode}`] = expropriations;
        this.totalsByState = { ...this.totalsByState, ...expropriationByState };
      });
    }
  }
}
