<template>
  <section id="wer-hat-der-gibt" class="w-screen bg-primary text-white py-16">
    <div class="container mx-auto px-5">
      <div class="max-read-width">
        <div class="max-w-max">
          <h3
            class="
              bg-white
              text-black
              py-1
              px-3
              font-semibold
              text-2xl
              tracking-wide
              inline
              heading
            "
          >
            Wer hat, der gibt
          </h3>
        </div>
        <p class="mt-12">
          <strong
            >Eine Gesellschaft, in der
            <a
              href="https://www.spiegel.de/wirtschaft/soziales/vermoegen-45-superreiche-besitzen-so-viel-wie-die-halbe-deutsche-bevoelkerung-a-1189111.html"
              title="45 Deutsche besitzen so viel wie die ärmere Hälfte der Bevölkerung | Spiegel Online vom 23.01.2018"
              target="_blank"
              class="underline hover:no-underline"
              >wenigen fast alles gehört</a
            >
            und
            <a
              href="https://www.zeit.de/wirtschaft/2020-07/vermoegensverteilung-deutschland-diw-studie-ungleichheit"
              title="»Das obere Prozent« | Die Zeit vom 14.07.2020"
              target="_blank"
              class="underline hover:no-underline"
              >sehr vielen praktisch nichts,</a
            >
            ist nicht zukunftsfähig.</strong
          >
        </p>
        <p class="mt-12">
          Enteignungen können helfen, das Wohl der Allgemeinheit wieder zu
          stärken. Dafür muss man sich aber trauen, das Instrument nicht nur
          gegen Laubenpieper einzusetzen, sondern auch gegen mächtige Konzerne.
          Weil die CDU aber an der Umverteilung von unten nach oben festhalten
          will, versteckt sie sich hinter ideologischer Propaganda.
        </p>
        <p class="mt-12">
          Tatsächlich fallen uns eher mehr als weniger Bereiche der Gesellschaft
          ein, in denen Enteignungen sinnvoll wären. Etwa zur Rekommunalisierung
          der Krankenhäuser, der Energie- und Wasserwerke, für eine ökologische
          Wende, für die Demokratisierung der Wirtschaft
          <a
            href="https://werhatdergibt.org/adventskalender/"
            title="Der »Wer hat, der gibt« Adventskalender der Enteignung"
            target="_blank"
            class="underline hover:no-underline"
            >oder oder oder.
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Summary extends Vue {}
</script>

<style scoped></style>
