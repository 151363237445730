<template>
  <!-- Hero -->
  <section
    class="
      w-screen
      bg-bottom bg-cover
      xl:bg-fixed
      bg-no-repeat
      flex flex-col
      justify-between
      relative
      hero
    "
  >
    <div class="fixed top-0 left-0 w-full z-10 h-20 header-bg"></div>
    <div class="sticks z-30">
      <div class="container mx-auto px-5 flex items-center">
        <!-- Logo -->
        <a href="/" title="CDU - Die Enteignungspartei" id="cdu-logo">
          <svg
            height="32"
            viewBox="0 0 54 16"
            width="108"
            xmlns="http://www.w3.org/2000/svg"
            class="cdu-logo"
          >
            <g
              fill="#BB232A"
              fill-rule="evenodd"
              id="cdu-logo-group"
              stroke="none"
              stroke-width="1"
            >
              <path
                d="M42.199.26L40.9 8.726c-.29 1.892.754 3.303 2.778 3.303 1.723 0 3.175-1.16 3.503-3.303L48.48.26h5.092l-1.22 7.952c-.672 4.383-3.116 7.4-9.036 7.4-3.934 0-6.116-1.422-7.057-3.256-.704-1.372-.736-2.987-.528-4.341L36.92.26h5.279z"
                id="cdu-c"
              ></path>
              <path
                d="M28.946 7.762c-.342 2.24-1.289 4.272-4.467 4.272h-2.756l1.31-8.548h2.456c1.177 0 1.888.184 2.428.628.897.74 1.262 2.12 1.029 3.648m-1.798-7.5h-8.693l-2.313 15.092h9.21c5.771 0 8.253-3.005 8.768-7.593.525-4.677-2.16-7.5-6.972-7.5"
                id="cdu-D"
              ></path>
              <path
                d="M14.78 11.335c-.76.235-2.502.435-4.236.435-3.911 0-5.265-1.838-5.12-4.009.16-2.417 2.248-4.125 6.304-4.125 1.636 0 3.385.419 3.81.551l.546-3.546C15.332.429 13.188 0 10.25 0 3.036 0 .569 3.987.099 6.905c-.66 4.104 1.958 8.709 8.91 8.709 2.216 0 4.48-.328 5.212-.668l.559-3.611z"
                id="cdu-U"
              ></path>
            </g>
          </svg>
        </a>
      </div>
    </div>

    <div class="relative z-0 container mx-auto px-5 title-container">
      <div class="max-w-max md:w-1/2 lg:w-3/5 xl:w-1/2">
        <h1
          :class="{ animate: isMounted }"
          class="
            inline
            text-2xl
            lg:text-4xl
            xl:text-5xl
            leading-normal
            lg:leading-normal
            xl:leading-snug
            font-semibold
            bg-gray-light
            p-2
            md:px-3
            lg:px-4
            title
          "
        >
          Egal, was wir euch erzählen: wir #enteignen.
        </h1>
      </div>
    </div>
    <div
      class="
        relative
        z-0
        container
        mx-auto
        text-center
        px-5
        sub-title-container
      "
    >
      <h2
        :class="{ animate: isMounted }"
        class="
          inline
          mx-auto
          text-4xl
          lg:text-5xl
          xl:text-6xl
          leading-none
          md:leading-normal
          xl:leading-normal
          font-medium
          bg-gray-light
          px-3
          md:py-2
          md:px-3
          lg:px-5
          sub-title
        "
      >
        Nur nicht da, wo wir sollten.
      </h2>
    </div>
  </section>

  <!-- Header -->
  <header class="fixed top-0 left-0 w-full z-50">
    <div class="md:flex md:items-center h-20 py-4 text-white">
      <div class="container mx-auto px-5 flex items-center justify-between">
        <!-- Logo placeholder -->
        <a
          href="/"
          title="CDU - Die Enteignungspartei"
          class="block w-28 h-12"
        ></a>
        <!-- Menu -->
        <div class="flex" aria-label="Main Menu" :aria-expanded="showMenu">
          <button
            @click="toggleMenu($event)"
            class="
              md:hidden
              md:w-0
              md:h-0
              relative
              z-30
              cursor-pointer
              menu-btn
            "
            :class="{ open: showMenu }"
          >
            <svg viewBox="0 0 100 100" width="50px" height="50px">
              <path
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                class="line line1"
              ></path>
              <path d="M 20,50 H 80" class="line line2"></path>
              <path
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                class="line line3"
              ></path>
            </svg>
          </button>
          <div class="menu-wrapper">
            <nav class="mt-24 md:mt-0 w-3/5 md:w-full">
              <ul class="md:flex text-sm uppercase tracking-wide font-semibold">
                <li
                  class="
                    border-b-2 border-white border-opacity-50
                    md:border-none
                  "
                >
                  <a
                    @click="toggleMenu($event)"
                    href="#zahlen-und-fakten"
                    class="
                      block
                      p-4
                      transition-colors
                      duration-300
                      ease-in-out
                      md:hover:bg-secondary
                      md:hover:text-gray-dark
                    "
                    >Zahlen und Fakten</a
                  >
                </li>
                <li
                  class="
                    border-b-2 border-white border-opacity-50
                    md:border-none
                  "
                >
                  <a
                    @click="toggleMenu($event)"
                    href="#beispiele"
                    class="
                      block
                      p-4
                      transition-colors
                      duration-300
                      ease-in-out
                      md:hover:bg-secondary
                      md:hover:text-gray-dark
                    "
                    >Beispiele</a
                  >
                </li>
                <li
                  class="
                    border-b-2 border-white border-opacity-50
                    md:border-none
                  "
                >
                  <a
                    @click="toggleMenu($event)"
                    href="#hintergrund"
                    class="
                      block
                      p-4
                      transition-colors
                      duration-300
                      ease-in-out
                      md:hover:bg-secondary
                      md:hover:text-gray-dark
                    "
                    >Hintergrund</a
                  >
                </li>
                <li
                  class="
                    border-b-2 border-white border-opacity-50
                    md:border-none
                  "
                >
                  <a
                    @click="toggleMenu($event)"
                    href="#wer-hat-der-gibt"
                    class="
                      block
                      p-4
                      transition-colors
                      duration-300
                      ease-in-out
                      md:hover:bg-secondary
                      md:hover:text-gray-dark
                    "
                    >Wer hat, der gibt</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { Vue } from "vue-class-component";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const window = { smoothScrollToElement: () => {} };

export default class Hero extends Vue {
  public isMounted = false;
  public showMenu = false;

  mounted(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isMounted = true;
      }, 600);

      // Make WHDG logo sticky when overlapping CDU
      this.observeStickyHeaderChanges(
        document.querySelector("#scroll-container")
      );
    });
  }

  public toggleMenu(event: Event): void {
    this.showMenu = !this.showMenu;
    async () => await window.smoothScrollToElement(); // Wait for scrolling to complete
    // Close the mobile menu when clicking on a menu item
    if (event && event.target) {
      const target = event.target as HTMLElement;
      if (target.nodeName.toLowerCase() === "a") {
        this.showMenu = false;
      }
    }
  }

  public observeStickyHeaderChanges(container: HTMLDivElement | null): void {
    this.observeHeaders(container);
    this.observeFooters(container);
  }

  public observeHeaders(container: HTMLDivElement | null): void {
    const observer = new IntersectionObserver(
      (
        records: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        for (const record of records) {
          if (record && record.target && record.target.parentElement) {
            const targetInfo = record.boundingClientRect as DOMRectReadOnly;
            const stickyTarget =
              record.target.parentElement.querySelector(".sticks");
            const rootBoundsInfo = record.rootBounds as DOMRectReadOnly;

            // Started sticking.
            if (targetInfo.bottom < rootBoundsInfo.top) {
              this.fireEvent(true, stickyTarget);
            }

            // Stopped sticking.
            if (
              targetInfo.bottom >= rootBoundsInfo.top &&
              targetInfo.bottom < rootBoundsInfo.bottom
            ) {
              this.fireEvent(false, stickyTarget);
            }
          }
        }
      },
      { threshold: [0], root: container }
    );

    // Add the top sentinels to each section and attach an observer.
    const sentinels = Array.from(
      document.querySelectorAll("sticky_sentinel--top")
    ) as HTMLDivElement[];
    if (sentinels) {
      sentinels.forEach((element: HTMLDivElement | null) => {
        if (element !== null) {
          return observer.observe(element);
        }
      });
    }
  }

  public observeFooters(container: HTMLDivElement | null): void {
    const observer = new IntersectionObserver(
      (
        records: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        for (const record of records) {
          if (record && record.target && record.target.parentElement) {
            const targetInfo = record.boundingClientRect as DOMRectReadOnly;
            const stickyTarget =
              record.target.parentElement.querySelector(".sticks");
            const rootBoundsInfo = record.rootBounds as DOMRectReadOnly;
            const ratio = record.intersectionRatio;

            // Started sticking.
            if (targetInfo.bottom > rootBoundsInfo.top && ratio === 1) {
              this.fireEvent(true, stickyTarget);
            }

            // Stopped sticking.
            if (
              targetInfo.top < rootBoundsInfo.top &&
              targetInfo.bottom < rootBoundsInfo.bottom
            ) {
              this.fireEvent(false, stickyTarget);
            }
          }
        }
      },
      { threshold: [1], root: container }
    );

    // Add the bottom sentinels to each section and attach an observer.
    const sentinels = Array.from(
      document.querySelectorAll("sticky_sentinel--bottom")
    ) as HTMLDivElement[];
    if (sentinels) {
      sentinels.forEach((element: HTMLDivElement | null) => {
        if (element !== null) {
          return observer.observe(element);
        }
      });
    }
  }

  public fireEvent(stuck: boolean, target: Element | null): void {
    const event = new CustomEvent("sticky-change", {
      detail: {
        stuck,
        target,
      },
    });
    document.dispatchEvent(event);
  }
}
</script>

<style>
/* HEADER */
.header-bg {
  @apply border-none;
  background-image: linear-gradient(
    to bottom,
    rgba(50, 48, 46, 0.8),
    rgba(50, 48, 46, 0)
  );
}
#cdu-logo {
  @apply h-8;
  width: 108px;
}
/* Mobile menu */
.menu-wrapper {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply hidden;
  @apply h-screen;
  @apply w-screen;
  @apply bg-gray-dark;
  @apply justify-center;
  @apply text-center;
}
.menu-btn.open + .menu-wrapper {
  @apply flex;
}
/* Burger Icon */
.menu-btn .line {
  fill: none;
  stroke: currentColor;
  stroke-width: 6;
  transition: stroke-dasharray 0.6s cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
/* Closed */
.menu-btn .line1,
.menu-btn .line3 {
  stroke-dasharray: 60 207;
}
.menu-btn .line2 {
  stroke-dasharray: 60 60;
}
/* Open */
.menu-btn.open .line1,
.menu-btn.open .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}
.menu-btn.open .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}

/* Main menu */
@screen md {
  .menu-wrapper {
    @apply flex;
    @apply static;
    @apply bg-transparent;
    @apply w-full;
    @apply h-auto;
    top: unset;
    left: unset;
  }
}

/* HERO animations */
@keyframes fadeInDown {
  0% {
    transform: translateY(-50%);
    @apply opacity-0;
  }
  100% {
    transform: translateY(0%);
    @apply opacity-100;
  }
}
.title-container,
.sub-title-container {
  top: -10vh;
}
.title,
.sub-title {
  transform: translateY(-50%);
  @apply opacity-0;
  hyphens: none;
}
.title.animate,
.sub-title.animate {
  animation: fadeInDown 800ms ease-in-out forwards;
}
.sub-title.animate {
  animation-delay: 1s;
}

.hero {
  background-image: url("../assets/img/CDU_Bundesgeschaeftsstelle_Berlin-by-Thomas_Rhiele-CC-BY-SA-2.0-DE-Wikimedia.jpg");
  height: 85vh;
}
h1,
h2 {
  @apply relative;
  @apply z-0;
  @apply break-words;
  @apply whitespace-normal;
  box-decoration-break: clone;
  hyphens: none;
}
@media screen and (max-width: 640px) and (orientation: landscape) {
  .hero {
    @apply h-screen;
  }
}
@screen md {
  .hero {
    background-image: url("../assets/img/Konrad-Adenauer-Haus_(Berlin)-by-Ansgar_Koreng-CC-BY-SA-3.0_(DE).jpg");
    height: 60vh;
  }
}
@screen lg {
  .hero {
    background-image: url("../assets/img/Konrad-Adenauer-Haus_(Berlin)-by-Ansgar_Koreng-CC-BY-SA-3.0_(DE).jpg");
    height: 80vh;
  }
}
</style>
