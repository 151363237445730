<template>
  <footer class="relative bottom-0 bg-primary text-white">
    <section class="bg-gray-dark py-20">
      <div class="container mx-auto px-5">
        <div class="max-read-width">
          <div class="max-w-max">
            <h3
              class="
                bg-white
                text-black
                py-1
                px-3
                font-semibold
                text-2xl
                tracking-wide
                inline
                heading
              "
            >
              Weiterführende Links
            </h3>
          </div>

          <p class="mt-12 text-base flex flex-col">
            <a
              href="https://www.dwenteignen.de/"
              title="Berliner Volksbegehren »Deutsche Wohnen &amp; Co. enteignen«"
              target="_blank"
              class="no-underline hover:underline max-w-max"
            >
              Berliner Volksbegehren »Deutsche Wohnen &amp; Co. enteignen«
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
            <a
              href="https://www.dwenteignen.de/on-air-list/"
              title="Podcast der Kampagne »Deutsche Wohnen &amp; Co. enteignen"
              target="_blank"
              class="mt-8 no-underline hover:underline max-w-max"
            >
              Podcast mit Hintergründen und Infos zur Kampagne »Deutsche Wohnen
              &amp; Co. enteignen
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
            <a
              href="https://www.mietenwahnsinn.info/"
              title="Berliner Bündnis gegen #Mietenwahnsinn"
              target="_blank"
              class="mt-8 no-underline hover:underline max-w-max"
            >
              Berliner Bündnis gegen #Mietenwahnsinn
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
            <a
              href="https://www.bmgev.de/"
              title="Berliner Mietergemeinschaft"
              target="_blank"
              class="mt-8 no-underline hover:underline max-w-max"
            >
              Berliner Mietergemeinschaft
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
            <a
              href="https://www.berliner-mieterverein.de/"
              title="Berliner Mieterverein"
              target="_blank"
              class="mt-8 no-underline hover:underline max-w-max"
            >
              Berliner Mieterverein
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
            <a
              href="https://keineprofitemitbodenundmiete.de/"
              title="Kampagne »Keine Profite mit Boden und Miete« für zwei Hamburger Volksinitiativen"
              target="_blank"
              class="mt-8 no-underline hover:underline max-w-max"
            >
              Hamburger Volksinitiativen »Keine Profite mit Boden und Miete«
              <i
                class="
                  fas
                  fa-angle-right
                  inline-block
                  bg-white
                  text-gray-dark text-2xl
                  px-2
                  h-8
                  w-8
                  rounded-full
                  text-center
                  ml-2
                  no-underline
                  hover:no-underline
                "
              ></i>
            </a>
          </p>
        </div>
      </div>
    </section>
    <section class="bg-secondary py-20">
      <div class="container mx-auto px-5">
        <div class="max-read-width">
          <div class="max-w-max">
            <h3
              class="
                bg-white
                text-black
                py-1
                px-3
                font-semibold
                text-2xl
                tracking-wide
                inline
                heading
              "
            >
              Bleiben Sie informiert.<br />Jetzt aktiv werden und für eine
              gerechtere Gesellschaft kämpfen:
            </h3>
          </div>
          <p class="mt-12">
            <a
              href="https://www.werhatdergibt.org/"
              title="Bündnis für Umverteilung »Wer hat, der gibt«"
              class="
                block
                text-center
                w-full
                md:w-4/5
                lg:w-2/5
                bg-primary
                px-5
                py-2
                text-lg
                rounded-sm
                btn
              "
              >Wer hat, der gibt</a
            >
          </p>
        </div>
      </div>
    </section>
    <section class="bg-gray-dark pt-20 pb-5 text-white">
      <div class="container mx-auto px-5">
        <div class="max-read-width">
          <div class="flex flex-col">
            <div
              class="
                flex flex-col
                sm:flex-row
                justify-between
                items-start
                sm:items-center
              "
            >
              <div class="max-w-max text-sm">
                <div>Wer hat, der gibt</div>
                <div class="mt-1">Bündnis für Umverteilung</div>
                <div class="mt-1 text-gray-500">und Enteignung ;)</div>
              </div>
              <div class="max-w-max mt-10 sm:mt-0">
                <a
                  href="https://www.facebook.com/buendniswerhatdergibt/"
                  title="Wer hat, der gibt bei Facebook"
                  target="_blank"
                  class="no-underline hover:underline inline-block social-link"
                >
                  <i
                    class="
                      fab
                      fa-facebook-f
                      inline-block
                      bg-white
                      text-gray-dark text-2xl
                      px-2
                      pt-1
                      h-10
                      w-10
                      rounded-full
                      text-center
                      no-underline
                      hover:no-underline
                    "
                  ></i>
                </a>
                <a
                  href="https://twitter.com/WerHatDerGibt"
                  title="Wer hat, der gibt bei Twitter"
                  target="_blank"
                  class="no-underline hover:underline inline-block social-link"
                >
                  <i
                    class="
                      fab
                      fa-twitter
                      inline-block
                      bg-white
                      text-gray-dark text-2xl
                      px-2
                      pt-1
                      h-10
                      w-10
                      rounded-full
                      text-center
                      ml-5
                      no-underline
                      hover:no-underline
                    "
                  ></i>
                </a>
                <a
                  href="https://www.instagram.com/wer_hat_der_gibt/"
                  title="Wer hat, der gibt bei Instagram"
                  target="_blank"
                  class="no-underline hover:underline inline-block social-link"
                >
                  <i
                    class="
                      fab
                      fa-instagram
                      inline-block
                      bg-white
                      text-gray-dark text-2xl
                      px-2
                      pt-1
                      h-10
                      w-10
                      rounded-full
                      text-center
                      ml-5
                      no-underline
                      hover:no-underline
                    "
                  ></i>
                </a>
                <a
                  href="https://werhatdergibt.org/telegram/"
                  title="Wer hat, der gibt bei Telegram"
                  target="_blank"
                  class="no-underline hover:underline inline-block social-link"
                >
                  <i
                    class="
                      fab
                      fa-telegram-plane
                      inline-block
                      bg-white
                      text-gray-dark text-2xl
                      px-2
                      pt-1
                      h-10
                      w-10
                      rounded-full
                      text-center
                      ml-5
                      no-underline
                      hover:no-underline
                    "
                  ></i>
                </a>
              </div>
            </div>
            <div class="max-w-max text-sm mt-5">
              <div class="mt-5">Bildnachweise:</div>
              <a
                href="https://de.wikipedia.org/wiki/Konrad-Adenauer-Haus_(Berlin)#/media/Datei:CDU_Bundesgeschaeftsstelle_Berlin.jpg"
                title="Bild: »CDU Bundesgeschäftsstelle Berlin« | Thomas Rhiele CC-BY-SA-2.0 @ Wikimedia"
                target="_blank"
                class="
                  underline
                  hover:no-underline
                  mt-1
                  font-thin
                  block
                  text-xs
                "
              >
                »CDU Bundesgeschäftsstelle Berlin« | Thomas Rhiele CC-BY-SA-2.0
                @ Wikimedia
              </a>
              <a
                href="https://de.wikipedia.org/wiki/Konrad-Adenauer-Haus_(Berlin)#/media/Datei:Konrad-Adenauer-Haus_(Berlin).jpg"
                title="Bild: »Konrad-Adenauer-Haus« | Ansgar Koreng
                CC-BY-SA-3.0 @ Wikimedia"
                target="_blank"
                class="
                  underline
                  hover:no-underline
                  mt-1
                  font-thin
                  block
                  text-xs
                "
              >
                »Konrad-Adenauer-Haus« | Ansgar Koreng CC-BY-SA-3.0 @ Wikimedia
              </a>
            </div>
            <div class="max-w-max text-sm mt-5 mb-6">
              <div class="mt-5 text-gray-300">
                Diese Seite verwendet keine Cookies.
              </div>
            </div>
            <hr class="border-t border-white border-solid my-5" />
            <div
              class="
                flex flex-col
                sm:flex-row
                justify-between
                items-start
                sm:items-center
              "
            >
              <a
                href="https://www.werhatdergibt.org/impressum/"
                target="_self"
                class="text-xs text-gray-light no-underline hover:underline"
                >Impressum | Datenschutz</a
              >
              <span class="text-xs"
                ><i class="fab fa-creative-commons"></i
                ><i class="fab fa-creative-commons-by ml-1"></i
                ><i class="fab fa-creative-commons-sa ml-1"></i
                ><i class="fab fa-creative-commons-nc-eu ml-1"></i
                ><span class="text-xs ml-1">{{ year }}</span
                ><span class="ml-2">Wer hat, der gibt</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Footer extends Vue {
  get year(): number {
    const d = new Date();
    const y = d.getFullYear();
    return y;
  }
}
</script>

<style scoped>
.heading {
  @apply whitespace-normal;
  box-decoration-break: clone;
  hyphens: none;
}
.btn,
.social-link {
  transform: scale(1);
  transition: transform 0.2s ease-out;
  will-change: transform;
}
.btn:hover,
.social-link:hover {
  transform: scale(0.95);
}
</style>
