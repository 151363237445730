<template>
  <section id="hintergrund" class="w-screen bg-white py-16">
    <div class="container mx-auto px-5">
      <div class="max-read-width">
        <h3
          class="
            max-w-max
            xl:max-w-xl
            font-black
            text-3xl
            leading-9
            tracking-wide
            uppercase
          "
        >
          Hintergrund
        </h3>
        <p class="mt-12">
          <strong class="tracking-wide"
            >Enteignungen sind laut Grundgesetz ausdrücklich erlaubt.</strong
          >
          Für den Bau von Autobahnen und Bundesstraßen fußen die Verfahren im
          Wesentlichen auf
          <a
            href="https://www.rnd.de/politik/so-viele-enteignungen-gibt-es-in-deutschland-fur-den-strassenbau-WEIFUKTDVFF77JMAENZ3JB6FA4.html"
            target="_blank"
            title="So viele Enteignungen gibt es in Deutschland für den Straßenbau | Redaktionsnetzwerk Deutschland vom 18.02.2020"
            class="text-primary underline hover:no-underline"
            >zwei gesetzlichen Grundlagen</a
          >:
        </p>
        <article class="flex flex-col md:flex-row items-stretch mt-16">
          <header class="flex flex-row items-center sm:flex-col mb-4 md:mb-0">
            <div class="book-icon">
              <i class="fa fa-book"></i>
            </div>
            <h4
              class="
                block
                sm:hidden
                text-black
                font-semibold
                text-2xl
                leading-none
                mb-3
              "
            >
              <b>Artikel 14</b> Grundgesetz
            </h4>
          </header>
          <main
            class="
              block
              w-full
              bg-gray-light
              text-gray-dark
              shadow-md
              py-6
              px-8
              mb-4
              rounded-sm
            "
          >
            <blockquote>
              <span
                class="hidden sm:block font-semibold text-2xl leading-none mb-3"
              >
                <b>Artikel 14</b> Grundgesetz
              </span>
              <ol class="ml-4 list-decimal">
                <li>
                  Das Eigentum und das Erbrecht werden gewährleistet. Inhalt und
                  Schranken werden durch die Gesetze bestimmt.
                </li>
                <li>
                  Eigentum verpflichtet. Sein Gebrauch soll zugleich dem Wohle
                  der Allgemeinheit dienen.
                </li>
                <li>
                  Eine Enteignung ist nur zum Wohle der Allgemeinheit zulässig.
                  Sie darf nur durch Gesetz oder auf Grund eines Gesetzes
                  erfolgen, das Art und Ausmaß der Entschädigung regelt. Die
                  Entschädigung ist unter gerechter Abwägung der Interessen der
                  Allgemeinheit und der Beteiligten zu bestimmen. Wegen der Höhe
                  der Entschädigung steht im Streitfalle der Rechtsweg vor den
                  ordentlichen Gerichten offen.
                </li>
              </ol>
              <cite class="block mt-2 cursor-pointer text-blue-900"
                ><a
                  href="https://www.gesetze-im-internet.de/gg/art_14.html"
                  target="_blank"
                  class="text-primary underline hover:no-underline"
                  >Gesetze im Internet | Bundesamt für Justiz</a
                ></cite
              >
            </blockquote>
          </main>
        </article>
        <article class="flex flex-col md:flex-row items-stretch mt-16">
          <header class="flex flex-row items-center sm:flex-col mb-4 md:mb-0">
            <div class="book-icon">
              <i class="fa fa-book"></i>
            </div>
            <h4
              class="
                block
                sm:hidden
                text-black
                font-semibold
                text-2xl
                leading-none
                mb-3
              "
            >
              <b>§ 19 "Enteignung"</b> Bundesfernstraßengesetz (FStrG)
            </h4>
          </header>
          <main
            class="
              block
              w-full
              bg-gray-light
              text-gray-dark
              shadow-md
              py-6
              px-8
              mb-4
              rounded-sm
            "
          >
            <blockquote>
              <span
                class="
                  hidden
                  sm:block
                  font-semibold
                  text-2xl
                  leading-tight
                  lg:leading-none
                  mb-3
                "
              >
                <b>§ 19 "Enteignung"</b> Bundesfernstraßengesetz (FStrG)
              </span>
              <ol class="ml-4 list-decimal">
                <li>
                  Die Träger der Straßenbaulast der Bundesfernstraßen haben zur
                  Erfüllung ihrer Aufgaben das Enteignungsrecht. Die Enteignung
                  ist zulässig, soweit sie zur Unterhaltung oder Ausführung
                  eines nach § 17 Absatz 1 festgestellten oder genehmigten
                  Bauvorhabens notwendig ist. Einer weiteren Feststellung der
                  Zulässigkeit der Enteignung bedarf es nicht.
                </li>
                <li>
                  Der festgestellte oder genehmigte Plan ist dem
                  Enteignungsverfahren zugrunde zu legen und für die
                  Enteignungsbehörde bindend.
                </li>
                <li>
                  Hat sich ein Beteiligter mit der Übertragung oder Beschränkung
                  des Eigentums oder eines anderen Rechts schriftlich
                  einverstanden erklärt, kann das Entschädigungsverfahren
                  unmittelbar durchgeführt werden.
                </li>
                <li>
                  Die Absätze 1, 2 und 2a gelten für die in § 17f genannten
                  Anlagen entsprechend.
                </li>
                <li>(weggefallen)</li>
                <li>(weggefallen)</li>
                <li>
                  Im Übrigen gelten die für öffentliche Straßen geltenden
                  Enteignungsgesetze der Länder.
                </li>
              </ol>
              <cite class="block mt-2 cursor-pointer text-blue-900"
                ><a
                  href="https://www.gesetze-im-internet.de/fstrg/__19.html"
                  target="_blank"
                  class="text-primary underline hover:no-underline"
                  >Gesetze im Internet | Bundesamt für Justiz</a
                ></cite
              >
            </blockquote>
          </main>
        </article>
        <p class="mt-12">
          Das Berliner Volksbegehren
          <a
            href="https://www.dwenteignen.de/"
            target="_blank"
            title="Berliner Volksbegehren »Deutsche Wohnen & Co. Enteignen«"
            class="text-primary underline hover:no-underline"
          >
            „Deutsche Wohnen &amp; Co enteignen“</a
          >
          beruft sich zudem auf den Grundgesetzartikel 15, der in der Geschichte
          der Bundesrepublik noch nie zur Anwendung kam und
          <strong
            >die angestrebte Überführung von Immobilien aus privatem
            Konzerneigentum in öffentlichen, nicht-staatlichen Besitz</strong
          >
          mit demokratischen Kontrollmöglichkeiten, überhaupt erst ermöglicht.
          Die FDP steht diesem Punkt der Verfassung feindlich gegenüber und
          <a
            href="https://www.fdp.de/sites/default/files/import/2019-05/7397-2019-04-26-bpt-entschieden-gegen-enteignung-durch-vergesellschaftung-bauen-statt-klauen.pdf"
            target="_blank"
            title="Beschluss der FDP zur Streichung von Grundgesetz Artikel 15 | PDF"
            class="text-primary underline hover:no-underline"
            >hat beschlossen, dieses Recht abschaffen zu wollen.
          </a>
        </p>
        <article class="flex flex-col md:flex-row items-stretch mt-16">
          <header class="flex flex-row items-center sm:flex-col mb-4 md:mb-0">
            <div class="book-icon">
              <i class="fa fa-book"></i>
            </div>
            <h4
              class="
                block
                sm:hidden
                text-black
                font-semibold
                text-2xl
                leading-none
                mb-3
              "
            >
              <b>Artikel 15</b> Grundgesetz
            </h4>
          </header>
          <main
            class="
              block
              w-full
              bg-gray-light
              text-gray-dark
              shadow-md
              py-6
              px-8
              mb-4
              rounded-sm
            "
          >
            <blockquote>
              <span
                class="hidden sm:block font-semibold text-2xl leading-none mb-3"
              >
                <b>Artikel 15</b> Grundgesetz
              </span>
              <p>
                Grund und Boden, Naturschätze und Produktionsmittel können zum
                Zwecke der Vergesellschaftung durch ein Gesetz, das Art und
                Ausmaß der Entschädigung regelt, in Gemeineigentum oder in
                andere Formen der Gemeinwirtschaft überführt werden. Für die
                Entschädigung gilt Artikel 14 Abs. 3 Satz 3 und 4 entsprechend.
              </p>
              <cite class="block mt-2 cursor-pointer text-blue-900"
                ><a
                  href="https://www.gesetze-im-internet.de/gg/art_15.html"
                  target="_blank"
                  class="text-primary underline hover:no-underline"
                  >Gesetze im Internet | Bundesamt für Justiz</a
                ></cite
              >
            </blockquote>
          </main>
        </article>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Context extends Vue {}
</script>

<style scoped>
.book-icon {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply text-secondary;
  @apply bg-white;
  @apply shadow;
  @apply mr-6;
  @apply text-4xl;
  @apply leading-none;
  @apply w-20;
  @apply h-20;
  @apply rounded-full;
  min-width: 5rem;
}
h3,
h4 {
  hyphens: auto;
}
p.first-letter::first-letter {
  @apply text-6xl;
  @apply leading-none;
  @apply float-left;
  @apply mr-1;
}
@supports (initial-letter: 1) or (-webkit-initial-letter: 1) {
  p.first-letter::first-letter {
    initial-letter: 3;
  }
}
</style>
