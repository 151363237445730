<template>
  <section id="zahlen-und-fakten" class="w-screen bg-white py-16">
    <div class="container mx-auto px-5">
      <div class="max-read-width">
        <h3
          class="
            max-w-max
            xl:max-w-xl
            font-black
            text-3xl
            leading-9
            tracking-wide
            uppercase
          "
        >
          CDU – Die Enteignungspartei
        </h3>
        <p class="mt-12">
          <a
            href="https://cdu-fraktion.berlin.de/index.php?ka=8&ska=47&idn=437"
            target="_blank"
            title="Webseite zum Beschluss der CDU-Fraktion Berlin vom 24.02.2021"
            class="text-primary underline hover:no-underline"
            >„Auf Enteignung kann Berlin nicht bauen“</a
          >, sagt die Berliner CDU und argumentiert mit dem
          „verfassungsrechtlichen Schutz des Eigentums“ sowie dem „Prinzip der
          sozialen Marktwirtschaft“. Diese Argumentation fällt der Partei
          ausgerechnet in dem Moment ein, wo von einer Enteignung privater
          Immobilienkonzerne endlich einmal eine ganze Stadtgesellschaft
          profitieren könnte.
        </p>
        <p class="mt-12">
          <strong
            >Wenn es aber gerade mal nicht um Mieter*innen, sondern um die
            Interessen von Konzernen, Baufirmen oder der Autolobby geht, dann
            greift die CDU so oft zum Mittel der Enteignung wie keine andere
            Partei in Deutschland.</strong
          >
        </p>
        <p class="mt-12">
          Laut einer Antwort des Bundesministeriums für Verkehr und digitale
          Infrastruktur auf eine entsprechende Berichtsanforderung des
          Abgeordneten MdB Sven-Christian Kindler vom August vergangenen Jahres,
          fanden im Zeitraum von 2009 bis Mai 2020 bundesweit insgesamt
          {{ annualTotalsNumber }} Enteignungsverfahren im Zusammenhang mit dem
          Bau von Autobahnen und Bundesstraßen statt. Im Schnitt also
          {{ annualTotalsAverage }} Enteignungen pro Jahr. Eine weitere Anfrage
          von letztem Februar lieferte zudem vollständigere Daten für das Jahr
          2020, aufgeschlüsselt nach Bundesländern.
        </p>
        <p class="mt-12">
          Weder die Anfragen noch die Antworten sind auf der
          <a
            href="https://dip.bundestag.de/"
            target="_blank"
            title="Dokumentations- und Informationssystem für Parlamentsmaterialien"
            class="text-primary underline hover:no-underline"
            >Dokumentationsseite des Deutschen Bundestages</a
          >
          einsehbar. Lediglich Auszüge waren in gelegentlichen Presseberichten
          enthalten.
          <strong
            >Wir veröffentlichen diese Daten hier daher erstmals in Gänze, um
            sie der Öffentlichkeit zugänglich zu machen.</strong
          >
        </p>
        <FactsChart v-if="annualTotals" :annualTotals="annualTotals" />
        <FactsMap v-if="states" :states="states" />
        <p class="mt-12">
          Obwohl CDU und CSU die DDR-Keule schwingen, sobald sie nur das Wort
          "Enteignung" hören,
          <strong
            >sind ausgerechnet unionsgeführte Bundesländer die Spitzenreiter
            beim Enteignen:</strong
          >
          Sachsen-Anhalt ({{ totalsByState?.ST }}), Baden-Württemberg ({{
            totalsByState?.BW
          }}), Brandenburg ({{ totalsByState?.BB }}), Sachsen ({{
            totalsByState?.SN
          }}), Nordrhein-Westfalen ({{ totalsByState?.NW }}), Bayern ({{
            totalsByState?.BY
          }}) und Schleswig-Holstein ({{ totalsByState?.SH }}). Wie sich auch
          <a
            href="https://www.tagesspiegel.de/politik/verkehrsministerium-200-enteignungen-fuer-den-strassenbau/24341202.html"
            target="_blank"
            title="200 Enteignungen für den Straßenbau | Tagesspiegel vom 14.05.2019"
            class="text-primary underline hover:no-underline mr-1:5"
            >regelmäßig</a
          >
          <a
            href="https://www.rnd.de/politik/so-viele-enteignungen-gibt-es-in-deutschland-fur-den-strassenbau-WEIFUKTDVFF77JMAENZ3JB6FA4.html"
            target="_blank"
            title="So viele Enteignungen gibt es in Deutschland für den Straßenbau | Redaktionsnetzwerk Deutschland vom 18.02.2020"
            class="text-primary underline hover:no-underline mr-1:5"
            >wiederkehrenden</a
          >
          <a
            href="https://www.nd-aktuell.de/artikel/1149373.vergesellschaftung-sachsen-ist-beim-enteignen-spitzenreiter.html?sstr=sachsen|spitzenreiter|enteignen"
            target="_blank"
            title="Sachsen ist beim Enteignen Spitzenreiter | Neues Deutschland vom 11.03.2021"
            class="text-primary underline hover:no-underline"
            >Presseberichten</a
          >
          entnehmen lässt, ist insbesondere die Zahl an Verfahren im seit jeher
          CDU-regierten Sachsen konstant hoch.
        </p>
        <FactsParties v-if="states" :states="states" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { defineAsyncComponent } from "vue";
// Custom stuff
import { IState } from "src/interfaces/state.interface";
import { IAnnualTotalExpropriations } from "src/interfaces/annual-total-expropriations.interface";
import { IStateExpropriationsByYear } from "src/interfaces/state-expropriations-by-year.interface";
import { IExpropriation } from "src/interfaces/expropriation.interface";

const FactsChart = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Chart.vue") as any
);
const FactsMap = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Map.vue") as any
);
const FactsParties = defineAsyncComponent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => import("./Facts-Parties.vue") as any
);

@Options({
  props: {
    states: Array,
    annualTotals: Array,
  },
  components: {
    FactsChart,
    FactsMap,
    FactsParties,
  },
})
export default class Facts extends Vue {
  // Statistical data
  states!: IState[];
  annualTotals!: IAnnualTotalExpropriations[];
  totalsByState: { [key: string]: number } = {};

  public get annualTotalsNumber(): number {
    return this.annualTotals
      ?.map((annualTotal: IAnnualTotalExpropriations) => annualTotal.total)
      .reduce((totalsSum: number, currentTotal) => {
        return (totalsSum += currentTotal);
      }, 0);
  }

  public get annualTotalsAverage(): number {
    const amount = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => annualTotal.total
    ).length;
    const sum = this.annualTotals?.reduce(
      (totalsSum: number, currentTotal: IAnnualTotalExpropriations) => {
        return (totalsSum += currentTotal.total);
      },
      0
    );
    return Math.floor(sum / amount);
  }

  mounted(): void {
    if (this.states) {
      this.states.forEach((state: IState) => {
        const stateCode = state.code;
        let expropriationByState: { [key: string]: number } = {};
        const expropriations = state.expropriationsByYear
          .filter((expropriationsByYear: IStateExpropriationsByYear) =>
            expropriationsByYear ? expropriationsByYear.year === 2020 : false
          )
          .flatMap(
            (expropriationsByYear: IStateExpropriationsByYear) =>
              expropriationsByYear.expropriations
          )
          .reduce((total: number, currentAction: IExpropriation) => {
            if (
              currentAction &&
              currentAction.amount &&
              currentAction.amount > 0
            ) {
              if (currentAction.amount === 0.5) {
                return (total += 1);
              } else {
                return (total += currentAction.amount);
              }
            } else {
              return total;
            }
          }, 0);
        expropriationByState[`${stateCode}`] = expropriations;
        this.totalsByState = { ...this.totalsByState, ...expropriationByState };
      });
    }
  }
}
</script>

<style scoped>
h3 {
  hyphens: auto;
}
</style>
