
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { Vue } from "vue-class-component";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const window = { smoothScrollToElement: () => {} };

export default class Hero extends Vue {
  public isMounted = false;
  public showMenu = false;

  mounted(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isMounted = true;
      }, 600);

      // Make WHDG logo sticky when overlapping CDU
      this.observeStickyHeaderChanges(
        document.querySelector("#scroll-container")
      );
    });
  }

  public toggleMenu(event: Event): void {
    this.showMenu = !this.showMenu;
    async () => await window.smoothScrollToElement(); // Wait for scrolling to complete
    // Close the mobile menu when clicking on a menu item
    if (event && event.target) {
      const target = event.target as HTMLElement;
      if (target.nodeName.toLowerCase() === "a") {
        this.showMenu = false;
      }
    }
  }

  public observeStickyHeaderChanges(container: HTMLDivElement | null): void {
    this.observeHeaders(container);
    this.observeFooters(container);
  }

  public observeHeaders(container: HTMLDivElement | null): void {
    const observer = new IntersectionObserver(
      (
        records: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        for (const record of records) {
          if (record && record.target && record.target.parentElement) {
            const targetInfo = record.boundingClientRect as DOMRectReadOnly;
            const stickyTarget =
              record.target.parentElement.querySelector(".sticks");
            const rootBoundsInfo = record.rootBounds as DOMRectReadOnly;

            // Started sticking.
            if (targetInfo.bottom < rootBoundsInfo.top) {
              this.fireEvent(true, stickyTarget);
            }

            // Stopped sticking.
            if (
              targetInfo.bottom >= rootBoundsInfo.top &&
              targetInfo.bottom < rootBoundsInfo.bottom
            ) {
              this.fireEvent(false, stickyTarget);
            }
          }
        }
      },
      { threshold: [0], root: container }
    );

    // Add the top sentinels to each section and attach an observer.
    const sentinels = Array.from(
      document.querySelectorAll("sticky_sentinel--top")
    ) as HTMLDivElement[];
    if (sentinels) {
      sentinels.forEach((element: HTMLDivElement | null) => {
        if (element !== null) {
          return observer.observe(element);
        }
      });
    }
  }

  public observeFooters(container: HTMLDivElement | null): void {
    const observer = new IntersectionObserver(
      (
        records: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        for (const record of records) {
          if (record && record.target && record.target.parentElement) {
            const targetInfo = record.boundingClientRect as DOMRectReadOnly;
            const stickyTarget =
              record.target.parentElement.querySelector(".sticks");
            const rootBoundsInfo = record.rootBounds as DOMRectReadOnly;
            const ratio = record.intersectionRatio;

            // Started sticking.
            if (targetInfo.bottom > rootBoundsInfo.top && ratio === 1) {
              this.fireEvent(true, stickyTarget);
            }

            // Stopped sticking.
            if (
              targetInfo.top < rootBoundsInfo.top &&
              targetInfo.bottom < rootBoundsInfo.bottom
            ) {
              this.fireEvent(false, stickyTarget);
            }
          }
        }
      },
      { threshold: [1], root: container }
    );

    // Add the bottom sentinels to each section and attach an observer.
    const sentinels = Array.from(
      document.querySelectorAll("sticky_sentinel--bottom")
    ) as HTMLDivElement[];
    if (sentinels) {
      sentinels.forEach((element: HTMLDivElement | null) => {
        if (element !== null) {
          return observer.observe(element);
        }
      });
    }
  }

  public fireEvent(stuck: boolean, target: Element | null): void {
    const event = new CustomEvent("sticky-change", {
      detail: {
        stuck,
        target,
      },
    });
    document.dispatchEvent(event);
  }
}
